import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaFutbol } from 'react-icons/fa';

export const Navbar: React.FC = () => {
  return (
    <>
      <nav className="navbar bg-purple-700 p-2 shadow-lg">
        <div className="navbar-container">
          <Link to="/" className="flex items-center">
            <FaFutbol className="text-white text-3xl mr-2" aria-hidden="true" />
            <span className="text-white text-xl font-bold">
              Football Analysis
            </span>
          </Link>
          <ul className="navbar-menu">
            <li className="navbar-item">
              <Link
                to="/"
                className="text-white hover:text-purple-300 transition duration-300 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50 rounded-md px-3 py-2"
              >
                Home
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
