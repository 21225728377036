import React from 'react';
import { MainMatchesData } from './MainMatchesData';
import { PageTitle } from './PageTitle';

interface Props {
  selectedItemData: MainMatchesData;
}

export const LastMatchesAndResultPrevision = ({ selectedItemData }: Props) => {
  if (!selectedItemData) {
    return <div>No data available</div>;
  }

  const TruncatedString = (value: string) => {
    if (value.length <= 12) {
      return <span>{value}</span>;
    } else {
      const truncatedText = value.substring(0, 15) + '...';
      return <span title={value}>{truncatedText}</span>;
    }
  };

  const getCellColorClass = (value: number): string => {
    let probabilityClass = '';
    if (value <= 10) {
      probabilityClass = 'dark-red cell-text';
    } else if (value <= 20) {
      probabilityClass = 'medium-red cell-text';
    } else if (value <= 30) {
      probabilityClass = 'light-red cell-text';
    } else if (value <= 40) {
      probabilityClass = 'dark-orange cell-text';
    } else if (value <= 50) {
      probabilityClass = 'light-orange cell-text';
    } else if (value <= 60) {
      probabilityClass = 'dark-yellow cell-text';
    } else if (value <= 70) {
      probabilityClass = 'light-yellow cell-text';
    } else if (value <= 80) {
      probabilityClass = 'light-green cell-text';
    } else if (value <= 90) {
      probabilityClass = 'medium-green cell-text';
    } else {
      probabilityClass = 'dark-green cell-text';
    }
    return probabilityClass;
  };

  const getMatchResultClass = (value: string): string => {
    if (value.includes('W')) {
      return 'light-green cell-text';
    }
    if (value.includes('L')) {
      return 'medium-red cell-text';
    }
    if (value.includes('N')) {
      return 'light-yellow cell-text';
    }

    return 'default';
  };
  return (
    <div className="LastMatchesAndResultPrevision">
      <div className="last5container">
        {/* Prima coppia di tabelle */}
        <div className="left-table">
          {/* Tabella Last 5 Home Matches */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Last 5 Home Matches</th>
                </tr>
                <tr>
                  <th>Home</th>
                  <th>Away</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {selectedItemData.Last5HomeMatches.map((match, index) => (
                  <tr key={index}>
                    <td>{TruncatedString(match.HomeTeam)}</td>
                    <td>{TruncatedString(match.AwayTeam)}</td>
                    <td className={getMatchResultClass(match.WLN)}>
                      {match.Result}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Tabella Last 5 Matches */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Last 5 Matches</th>
                </tr>
                <tr>
                  <th>Home</th>
                  <th>Away</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {selectedItemData.Last5MatchesHomeTeam.map((match, index) => (
                  <tr key={index}>
                    <td>{TruncatedString(match.HomeTeam)}</td>
                    <td>{TruncatedString(match.AwayTeam)}</td>
                    <td className={getMatchResultClass(match.WLN)}>
                      {match.Result}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Tabelle al centro */}
        <div className="center-table">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <div className="biggerFont">
                      {selectedItemData.MatchDate}
                    </div>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Home</th>
                  <th>Away</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="biggerFont">{selectedItemData.Home}</div>
                  </td>
                  <td>
                    <div className="biggerFont">{selectedItemData.Away}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Tabella dei punti */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={2}>Team Form</th>
                </tr>
                <tr>
                  <th>Home</th>
                  <th>Away</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={getCellColorClass(selectedItemData.HomePoints)}
                  >
                    <div className="biggerFont">
                      {selectedItemData.HomePoints}
                    </div>
                  </td>
                  <td
                    className={getCellColorClass(selectedItemData.AwayPoints)}
                  >
                    <div className="biggerFont">
                      {selectedItemData.AwayPoints}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Tabella Default */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Outcome</th>
                </tr>
                <tr>
                  <th>1</th>
                  <th>X</th>
                  <th>2</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Default_1,
                    )}
                  >
                    {selectedItemData.Result1X2.Default_1}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Default_X,
                    )}
                  >
                    {selectedItemData.Result1X2.Default_X}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Default_2,
                    )}
                  >
                    {selectedItemData.Result1X2.Default_2}%
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan={3}>Poisson</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Poisson_1,
                    )}
                  >
                    {selectedItemData.Result1X2.Poisson_1}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Poisson_X,
                    )}
                  >
                    {selectedItemData.Result1X2.Poisson_X}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Poisson_2,
                    )}
                  >
                    {selectedItemData.Result1X2.Poisson_2}%
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th colSpan={3}>Combined</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Combined_1,
                    )}
                  >
                    {selectedItemData.Result1X2.Combined_1}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Combined_X,
                    )}
                  >
                    {selectedItemData.Result1X2.Combined_X}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.Result1X2.Combined_2,
                    )}
                  >
                    {selectedItemData.Result1X2.Combined_2}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Seconde coppia di tabelle a destra */}
        <div className="right-table">
          {/* Tabella Last 5 Home Matches */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Last 5 Away Matches</th>
                </tr>
                <tr>
                  <th>Home</th>
                  <th>Away</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {selectedItemData.Last5AwayMatches.map((match, index) => (
                  <tr key={index}>
                    <td>{TruncatedString(match.HomeTeam)}</td>
                    <td>{TruncatedString(match.AwayTeam)}</td>
                    <td className={getMatchResultClass(match.WLN)}>
                      {match.Result}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Tabella Last 5 Matches */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Last 5 Matches</th>
                </tr>
                <tr>
                  <th>Home</th>
                  <th>Away</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {selectedItemData.Last5MatchesAwayTeam.map((match, index) => (
                  <tr key={index}>
                    <td>{TruncatedString(match.HomeTeam)}</td>
                    <td>{TruncatedString(match.AwayTeam)}</td>
                    <td className={getMatchResultClass(match.WLN)}>
                      {match.Result}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
