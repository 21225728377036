import React from 'react';
import { MainMatchesData, MainMatchesPrevisionData } from './MainMatchesData';
import { useNavigate } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import { Header } from './Header';

interface Props {
  data: MainMatchesPrevisionData[];
  renderItem?: (item: MainMatchesPrevisionData) => JSX.Element;
}

export const MatchList = ({ data }: Props) => {
  const navigate = useNavigate();
  const columns = React.useMemo<Column<MainMatchesPrevisionData>[]>(
    () => [
      { Header: 'Country', accessor: 'Country' },
      { Header: 'League', accessor: 'League' },
      {
        Header: 'Date',
        accessor: 'MatchDate',
        Cell: ({ value }) => {
          const formattedDate = value.replace('T', ' ').slice(0, -3);
          return <div>{formattedDate}</div>;
        },
      },
      { Header: 'Home', accessor: 'Home' },
      { Header: 'Away', accessor: 'Away' },
      {
        Header: 'Prediction',
        accessor: (row) =>
          row.PredictionType === 'Result'
            ? row.ExactResult
            : row.PredictionType,
      },
      {
        Header: 'Probability',
        accessor: 'Prob',
        Cell: ({ value }) => {
          let probabilityClass = '';
          if (value <= 25) {
            probabilityClass = 'medium-red';
          } else if (value < 40) {
            probabilityClass = 'light-orange';
          } else if (value <= 59) {
            probabilityClass = 'light-yellow';
          } else if (value > 59 && value < 80) {
            probabilityClass = 'light-green';
          } else {
            probabilityClass = 'medium-green';
          }
          return (
            <div className={`cell-text ${probabilityClass}`}>{value}%</div>
          );
        },
      },
      // { Header: 'Result', accessor: 'MatchResult' },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const handleRowClick = (matchId: number) => {
    navigate(`/MatchAnalysis/${matchId}`);
  };

  return (
    <div className="">
      <table
        className="min-w-full divide-y divide-gray-200"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row.original.MatchId)}
                className="hover:bg-gray"
                style={{ cursor: 'pointer' }}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-sm"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
