import { FaFootballBall, FaCalendarAlt, FaRandom } from 'react-icons/fa';
import { BsGraphDown, BsGraphUp, BsGripHorizontal } from 'react-icons/bs';
import {
  MdAlignHorizontalLeft,
  MdAlignHorizontalRight,
  MdAlignVerticalBottom,
  MdBarChart,
  MdSportsSoccer,
} from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { MatchList } from './MatchList';
import {
  getDrawPredictions,
  getExactResultPredictions,
  getGoalGoalPredictions,
  getNoGoalPredictions,
  getOver2Point5Predictions,
  getUnder3Point5Predictions,
  MainMatchesPrevisionData,
  getOver1Point5Predictions,
  getMultigol2_5Predictions,
  getMultigol1_3Predictions,
  getMultigol1_3HomePredictions,
  getMultigol1_3AwayPredictions,
  getUnder1Point5HomePredictions,
  getUnder1Point5AwayPredictions,
} from './MainMatchesData';
import { PageTitle } from './PageTitle';
import { Page } from './Page';

const MainPage = () => {
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem('selectedDate') ||
      new Date().toISOString().split('T')[0],
  );
  const [selectedBet, setSelectedBet] = useState(
    localStorage.getItem('selectedBet') || 'GoalGoal',
  );
  const [matches, setMatches] = useState<MainMatchesPrevisionData[]>([]);
  const [matchesLoading, setMatchesLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  // Save filters when user selects a new filter
  useEffect(() => {
    localStorage.setItem('selectedDate', selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem('selectedBet', selectedBet);
  }, [selectedBet]);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    if (!localStorage.getItem('selectedDate')) {
      setSelectedDate(today);
    }
    if (dateInputRef.current) {
      if (selectedDate === today) {
        dateInputRef.current.classList.add('bg-white');
        dateInputRef.current.classList.remove('bg-customPurple', 'text-white');
      } else {
        dateInputRef.current.classList.add('bg-customPurple', 'text-white');
        dateInputRef.current.classList.remove('bg-white');
      }
    }
    handleButtonClick(selectedBet);
  }, []);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    // Ottieni la data corrente in formato YYYY-MM-DD
    const today = new Date().toISOString().split('T')[0];

    // Modifica la classe dell'input in base alla data selezionata
    if (dateInputRef.current) {
      if (newDate === today) {
        dateInputRef.current.classList.add('bg-white');
        dateInputRef.current.classList.remove('bg-customPurple', 'text-white');
      } else {
        dateInputRef.current.classList.add('bg-customPurple', 'text-white');
        dateInputRef.current.classList.remove('bg-white');
      }
    }

    // Passa la nuova data e il tipo selezionato per l'aggiornamento
    handleButtonClick(selectedBet, newDate);
  };

  const doGetGoalGoalPredictions = async (date: string) => {
    const retMatches = await getGoalGoalPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetNoGoalPredictions = async (date: string) => {
    const retMatches = await getNoGoalPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetOver2Point5Predictions = async (date: string) => {
    const retMatches = await getOver2Point5Predictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetUnder3Point5Predictions = async (date: string) => {
    const retMatches = await getUnder3Point5Predictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetDrawPredictions = async (date: string) => {
    const retMatches = await getDrawPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetOver1Point5ResultPredictions = async (date: string) => {
    const retMatches = await getOver1Point5Predictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetMultigol2_5Predictions = async (date: string) => {
    const retMatches = await getMultigol2_5Predictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetMultigol1_3Predictions = async (date: string) => {
    const retMatches = await getMultigol1_3Predictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetMultigol1_3HomePredictions = async (date: string) => {
    const retMatches = await getMultigol1_3HomePredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetMultigol1_3AwayPredictions = async (date: string) => {
    const retMatches = await getMultigol1_3AwayPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };
  const doGetUnder1Point5HomePredictions = async (date: string) => {
    const retMatches = await getUnder1Point5HomePredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };
  const doGetUnder1Point5AwayPredictions = async (date: string) => {
    const retMatches = await getUnder1Point5AwayPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };
  const doGetExactResultPredictions = async (date: string) => {
    const retMatches = await getExactResultPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const handleButtonClick = async (type: string, date?: string) => {
    setMatchesLoading(true);
    const searchDate = date || selectedDate; // Usa la data passata, altrimenti il selectedDate

    switch (type) {
      case 'GoalGoal':
        await doGetGoalGoalPredictions(searchDate); // Usa searchDate
        break;
      case 'NoGoal':
        await doGetNoGoalPredictions(searchDate); // Usa searchDate
        break;
      case 'Over2.5':
        await doGetOver2Point5Predictions(searchDate); // Usa searchDate
        break;
      case 'Under3.5':
        await doGetUnder3Point5Predictions(searchDate); // Usa searchDate
        break;
      case 'Draw':
        await doGetDrawPredictions(searchDate); // Usa searchDate
        break;
      case 'ExactResult':
        await doGetExactResultPredictions(searchDate); // Usa searchDate
        break;
      case 'Over1.5':
        await doGetOver1Point5ResultPredictions(searchDate); // Usa searchDate
        break;
      case 'Multigol2_5':
        await doGetMultigol2_5Predictions(searchDate); // Usa searchDate
        break;
      case 'Multigol1_3':
        await doGetMultigol1_3Predictions(searchDate); // Usa searchDate
        break;
      case 'Multigol1_3H':
        await doGetMultigol1_3HomePredictions(searchDate); // Usa searchDate
        break;
      case 'Multigol1_3A':
        await doGetMultigol1_3AwayPredictions(searchDate); // Usa searchDate
        break;
      case 'Under1.5H':
        await doGetUnder1Point5HomePredictions(searchDate); // Usa searchDate
        break;
      case 'Under1.5A':
        await doGetUnder1Point5AwayPredictions(searchDate); // Usa searchDate
        break;
      default:
        break;
    }
  };
  const betTypes = [
    { id: 'GoalGoal', label: 'Both Teams to Score', icon: <FaRandom /> },
    { id: 'Over1.5', label: 'Over 1.5', icon: <BsGraphUp /> },
    { id: 'Under3.5', label: 'Under 3.5', icon: <BsGraphDown /> },
    { id: 'Multigol2_5', label: 'Multi 2-5', icon: <MdBarChart /> },
    { id: 'Multigol1_3', label: 'Multi 1-3', icon: <MdAlignVerticalBottom /> },
    {
      id: 'Multigol1_3H',
      label: 'Multi 1-3 Home',
      icon: <MdAlignHorizontalLeft />,
    },
    {
      id: 'Multigol1_3A',
      label: 'Multi 1-3 Away',
      icon: <MdAlignHorizontalRight />,
    },
    { id: 'Under1.5H', label: 'Under 1.5 Home', icon: <BsGraphDown /> },
    { id: 'Under1.5A', label: 'Under 1.5 Away', icon: <BsGraphDown /> },
    // { id: 'Draw', label: 'Draw', icon: <BsGripHorizontal /> },
    { id: 'ExactResult', label: 'Result', icon: <MdSportsSoccer /> },
  ];
  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 mt-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8 text-stroke">
          Football Match Predictions
        </h1>

        <div className="mb-6 flex flex-wrap items-center justify-center gap-4">
          {betTypes.map((betType) => (
            <button
              key={betType.id}
              onClick={() => {
                setSelectedBet(betType.id);
                handleButtonClick(betType.id);
              }}
              className={`flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                selectedBet === betType.id
                  ? 'bg-customPurple text-white border border-gray-300'
                  : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
            >
              <span className="mr-2">{betType.icon}</span>
              {betType.label}
            </button>
          ))}

          <div className="relative">
            <input
              type="date"
              id="matchDate"
              name="matchDate"
              value={selectedDate}
              onChange={handleDateChange}
              ref={dateInputRef}
              className="pl-10 pr-4 py-2 rounded-full text-sm bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>
        <div className="overflow-x-auto shadow-md rounded-lg">
          {matchesLoading ? (
            <div className="text-center py-4 text-gray-200">Loading...</div>
          ) : matches && matches.length > 0 ? (
            <MatchList data={matches} />
          ) : (
            <div className="text-center py-4 text-gray-200">
              No matches found for the selected criteria.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
