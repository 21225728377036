import React, { useMemo } from 'react';
import { MainMatchesData } from './MainMatchesData';
import { Header } from './Header';

interface Props {
  selectedItemData: MainMatchesData;
}

export const GoalsPrediction = ({ selectedItemData }: Props) => {
  if (!selectedItemData) {
    return <div>No data available</div>;
  }
  const getCellColorClass = (value: number): string => {
    let probabilityClass = '';
    if (value <= 10) {
      probabilityClass = 'dark-red cell-text';
    } else if (value <= 20) {
      probabilityClass = 'medium-red cell-text';
    } else if (value <= 30) {
      probabilityClass = 'light-red cell-text';
    } else if (value <= 40) {
      probabilityClass = 'dark-orange cell-text';
    } else if (value <= 50) {
      probabilityClass = 'light-orange cell-text';
    } else if (value <= 60) {
      probabilityClass = 'dark-yellow cell-text';
    } else if (value <= 70) {
      probabilityClass = 'light-yellow cell-text';
    } else if (value <= 80) {
      probabilityClass = 'light-green cell-text';
    } else if (value <= 90) {
      probabilityClass = 'medium-green cell-text';
    } else {
      probabilityClass = 'dark-green cell-text';
    }
    return probabilityClass;
  };

  const getCellColorClassPossibleResult = (value: number): string => {
    let probabilityClass = '';
    if (value <= 4) {
      probabilityClass = 'dark-red cell-text';
    } else if (value <= 6) {
      probabilityClass = 'medium-red cell-text';
    } else if (value <= 7) {
      probabilityClass = 'dark-orange cell-text';
    } else if (value <= 9) {
      probabilityClass = 'light-yellow cell-text';
    } else if (value <= 15) {
      probabilityClass = 'light-green cell-text';
    } else if (value <= 25) {
      probabilityClass = 'medium-green cell-text';
    } else {
      probabilityClass = 'dark-green cell-text';
    }
    return probabilityClass;
  };

  const getCellColorClassGoalProb = (value: number): string => {
    let probabilityClass = '';
    if (value <= 7) {
      probabilityClass = 'medium-red cell-text';
    } else if (value < 12) {
      probabilityClass = 'dark-orange cell-text';
    } else if (value <= 19) {
      probabilityClass = 'light-yellow cell-text';
    } else if (value > 19 && value < 28) {
      probabilityClass = 'light-green cell-text';
    } else {
      probabilityClass = 'medium-green cell-text';
    }
    return probabilityClass;
  };

  return (
    <div className="TeamsAverageGoals">
      <div className="last5container">
        {/* Tabella 1 */}
        <div className="left-table">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Home Matches Only</th>
                </tr>
                <tr>
                  <th>Over 1.5</th>
                  <th>Over 2.5</th>
                  <th>Over 3.5</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeTeamHomeMatchesData.Over15}</td>
                  <td>{selectedItemData.HomeTeamHomeMatchesData.Over25}</td>
                  <td>{selectedItemData.HomeTeamHomeMatchesData.Over35}</td>
                </tr>
                <tr>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.HomeTeamHomeMatchesData.Over15 /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.HomeTeamHomeMatchesData.Over15 /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.HomeTeamHomeMatchesData.Over25 /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.HomeTeamHomeMatchesData.Over25 /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.HomeTeamHomeMatchesData.Over35 /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.HomeTeamHomeMatchesData.Over35 /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Clean Sheet</th>
                  <th>Goal Goal</th>
                  <th>No Goal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeTeamHomeMatchesData.CleanSheet}</td>
                  <td>{selectedItemData.HomeTeamHomeMatchesData.GoalGoal}</td>
                  <td>{selectedItemData.HomeTeamHomeMatchesData.NoGoal}</td>
                </tr>
                <tr>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.HomeTeamHomeMatchesData.CleanSheet /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.HomeTeamHomeMatchesData.CleanSheet /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.HomeTeamHomeMatchesData.GoalGoal /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.HomeTeamHomeMatchesData.GoalGoal /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.HomeTeamHomeMatchesData.NoGoal /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.HomeTeamHomeMatchesData.NoGoal /
                        selectedItemData.HomeTeamHomeMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Home Matches Only</th>
                </tr>
                <tr>
                  <th>Home Goal</th>
                  <th>Probability</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>0</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.HomeTeamProbabilities.Goals.Goals_0,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Goals.Goals_0}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>1</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.HomeTeamProbabilities.Goals.Goals_1,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Goals.Goals_1}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>2</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.HomeTeamProbabilities.Goals.Goals_2,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Goals.Goals_2}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>3</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.HomeTeamProbabilities.Goals.Goals_3,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Goals.Goals_3}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>4</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.HomeTeamProbabilities.Goals.Goals_4,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Goals.Goals_4}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>5</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.HomeTeamProbabilities.Goals.Goals_5,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Goals.Goals_5}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={4}>Home Matches Only</th>
                </tr>
                <tr>
                  <th>Over Home</th>
                  <th>Probability</th>
                  <th>Under Home</th>
                  <th>Probability</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>0.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Over.Over_05,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Over.Over_05}%
                  </td>
                  <td>
                    <b>0.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Under.Under_05,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Under.Under_05}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>1.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Over.Over_15,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Over.Over_15}%
                  </td>
                  <td>
                    <b>1.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Under.Under_15,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Under.Under_15}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>2.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Over.Over_25,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Over.Over_25}%
                  </td>
                  <td>
                    <b>2.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Under.Under_25,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Under.Under_25}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>3.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Over.Over_35,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Over.Over_35}%
                  </td>
                  <td>
                    <b>3.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Under.Under_35,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Under.Under_35}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>4.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Over.Over_45,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Over.Over_45}%
                  </td>
                  <td>
                    <b>4.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Under.Under_45,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Under.Under_45}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>5.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Over.Over_55,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Over.Over_55}%
                  </td>
                  <td>
                    <b>5.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.HomeTeamProbabilities.Under.Under_55,
                    )}
                  >
                    {selectedItemData.HomeTeamProbabilities.Under.Under_55}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Shots</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Home AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeShots.Total}</td>
                  <td>{selectedItemData.HomeShots.AverageAtHomeOrAway}</td>
                  <td>{selectedItemData.HomeShots.AverageTotal}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total On Target</th>
                  <th>Home OT AVG</th>
                  <th>Total OT AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeShots.OnTargetTotal}</td>
                  <td>
                    {selectedItemData.HomeShots.OnTargetAverageAtHomeOrAway}
                  </td>
                  <td>{selectedItemData.HomeShots.OnTargetAverageTotal}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Shots Against</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Home AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeShots.TotalVs}</td>
                  <td>{selectedItemData.HomeShots.AverageVsAtHomeOrAway}</td>
                  <td>{selectedItemData.HomeShots.AverageTotalVs}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total On Target</th>
                  <th>Home OT AVG</th>
                  <th>Total OT AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeShots.OnTargetTotalVs}</td>
                  <td>
                    {selectedItemData.HomeShots.OnTargetVsAverageAtHomeOrAway}
                  </td>
                  <td>{selectedItemData.HomeShots.OnTargetAverageTotalVs}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="center-table">
          <div className="table-container">
            {/* Tabella 2 */}
            <table>
              <thead>
                <tr>
                  <th colSpan={4}>Expected Goals</th>
                </tr>
                <tr>
                  <th colSpan={2}>Home</th>
                  <th colSpan={2}>Away</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <div className="biggerFont">
                      {selectedItemData.MatchProbabilities.ExpectedGoalHome}
                    </div>
                  </td>
                  <td colSpan={2}>
                    <div className="biggerFont">
                      {selectedItemData.MatchProbabilities.ExpectedGoalAway}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-container">
            {/* Tabella 2 */}
            <table>
              <thead>
                <tr>
                  <th colSpan={4}>Probability</th>
                </tr>
                <tr>
                  <th colSpan={2}>Goal Goal</th>
                  <th colSpan={2}>No Goal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={2}
                    className={`${getCellColorClass(selectedItemData.MatchProbabilities.GoalGoal)} biggerFont`}
                  >
                    {selectedItemData.MatchProbabilities.GoalGoal}%
                  </td>
                  <td
                    colSpan={2}
                    className={`${getCellColorClass(selectedItemData.MatchProbabilities.NoGoal)} biggerFont`}
                  >
                    {selectedItemData.MatchProbabilities.NoGoal}%
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}> - </td>
                  <td colSpan={2}> - </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-container">
            {/* Tabella 2 */}
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Over Probability</th>
                </tr>
                <tr>
                  <th>
                    <b>0.5</b>
                  </th>
                  <th>
                    <b>1.5</b>
                  </th>
                  <th>
                    <b>2.5</b>
                  </th>
                  <th>
                    <b>3.5</b>
                  </th>
                  <th>
                    <b>4.5</b>
                  </th>
                  <th>
                    <b>5.5</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Over.Over_05,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Over.Over_05}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Over.Over_15,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Over.Over_15}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Over.Over_25,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Over.Over_25}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Over.Over_35,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Over.Over_35}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Over.Over_45,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Over.Over_45}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Over.Over_55,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Over.Over_55}%
                  </td>
                </tr>
                <tr>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            {/* Tabella 2 */}
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Under Probability</th>
                </tr>
                <tr>
                  <th>
                    <b>0.5</b>
                  </th>
                  <th>
                    <b>1.5</b>
                  </th>
                  <th>
                    <b>2.5</b>
                  </th>
                  <th>
                    <b>3.5</b>
                  </th>
                  <th>
                    <b>4.5</b>
                  </th>
                  <th>
                    <b>5.5</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Under.Under_05,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Under.Under_05}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Under.Under_15,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Under.Under_15}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Under.Under_25,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Under.Under_25}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Under.Under_35,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Under.Under_35}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Under.Under_45,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Under.Under_45}%
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.MatchProbabilities.Under.Under_55,
                    )}
                  >
                    {selectedItemData.MatchProbabilities.Under.Under_55}%
                  </td>
                </tr>
                <tr>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            {/* Tabella 2 */}
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Possible Results</th>
                </tr>
                <tr>
                  <th>Home</th>
                  <th>Away</th>
                  <th>Probability</th>
                  <th>Odd</th>
                </tr>
              </thead>
              <tbody>
                {selectedItemData.MatchProbabilities.PossibleResults.map(
                  (match, index) => (
                    <tr key={index}>
                      <td>{match.Home}</td>
                      <td>{match.Away}</td>
                      <td
                        className={getCellColorClassPossibleResult(
                          match.Probability,
                        )}
                      >
                        {match.Probability}%
                      </td>
                      <td>{match.Odd}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Tabella 3 */}
        <div className="right-table">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Away Matches Only</th>
                </tr>
                <tr>
                  <th>Over 1.5</th>
                  <th>Over 2.5</th>
                  <th>Over 3.5</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayTeamAwayMatchesData.Over15}</td>
                  <td>{selectedItemData.AwayTeamAwayMatchesData.Over25}</td>
                  <td>{selectedItemData.AwayTeamAwayMatchesData.Over35}</td>
                </tr>
                <tr>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.AwayTeamAwayMatchesData.Over15 /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.AwayTeamAwayMatchesData.Over15 /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.AwayTeamAwayMatchesData.Over25 /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.AwayTeamAwayMatchesData.Over25 /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.AwayTeamAwayMatchesData.Over35 /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.AwayTeamAwayMatchesData.Over35 /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Clean Sheet</th>
                  <th>Goal Goal</th>
                  <th>No Goal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayTeamAwayMatchesData.CleanSheet}</td>
                  <td>{selectedItemData.AwayTeamAwayMatchesData.GoalGoal}</td>
                  <td>{selectedItemData.AwayTeamAwayMatchesData.NoGoal}</td>
                </tr>
                <tr>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.AwayTeamAwayMatchesData.CleanSheet /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.AwayTeamAwayMatchesData.CleanSheet /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.AwayTeamAwayMatchesData.GoalGoal /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.AwayTeamAwayMatchesData.GoalGoal /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                  <td
                    className={getCellColorClass(
                      (selectedItemData.AwayTeamAwayMatchesData.NoGoal /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                  >
                    {Math.floor(
                      (selectedItemData.AwayTeamAwayMatchesData.NoGoal /
                        selectedItemData.AwayTeamAwayMatchesData.TotalMatches) *
                        100,
                    )}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Away Matches Only</th>
                </tr>
                <tr>
                  <th>Away Goal</th>
                  <th>Probability</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>0</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.AwayTeamProbabilities.Goals.Goals_0,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Goals.Goals_0}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>1</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.AwayTeamProbabilities.Goals.Goals_1,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Goals.Goals_1}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>2</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.AwayTeamProbabilities.Goals.Goals_2,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Goals.Goals_2}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>3</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.AwayTeamProbabilities.Goals.Goals_3,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Goals.Goals_3}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>4</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.AwayTeamProbabilities.Goals.Goals_4,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Goals.Goals_4}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>5</b>
                  </td>
                  <td
                    className={getCellColorClassGoalProb(
                      selectedItemData.AwayTeamProbabilities.Goals.Goals_5,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Goals.Goals_5}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={4}>Away Matches Only</th>
                </tr>
                <tr>
                  <th>Over Away</th>
                  <th>Probability</th>
                  <th>Under Away</th>
                  <th>Probability</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>0.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Over.Over_05,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Over.Over_05}%
                  </td>
                  <td>
                    <b>0.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Under.Under_05,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Under.Under_05}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>1.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Over.Over_15,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Over.Over_15}%
                  </td>
                  <td>
                    <b>1.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Under.Under_15,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Under.Under_15}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>2.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Over.Over_25,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Over.Over_25}%
                  </td>
                  <td>
                    <b>2.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Under.Under_25,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Under.Under_25}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>3.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Over.Over_35,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Over.Over_35}%
                  </td>
                  <td>
                    <b>3.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Under.Under_35,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Under.Under_35}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>4.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Over.Over_45,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Over.Over_45}%
                  </td>
                  <td>
                    <b>4.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Under.Under_45,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Under.Under_45}%
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>5.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Over.Over_55,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Over.Over_55}%
                  </td>
                  <td>
                    <b>5.5</b>
                  </td>
                  <td
                    className={getCellColorClass(
                      selectedItemData.AwayTeamProbabilities.Under.Under_55,
                    )}
                  >
                    {selectedItemData.AwayTeamProbabilities.Under.Under_55}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Shots</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Away AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayShots.Total}</td>
                  <td>{selectedItemData.AwayShots.AverageAtHomeOrAway}</td>
                  <td>{selectedItemData.AwayShots.AverageTotal}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total On Target</th>
                  <th>Away OT AVG</th>
                  <th>Total OT AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayShots.OnTargetTotal}</td>
                  <td>
                    {selectedItemData.AwayShots.OnTargetAverageAtHomeOrAway}
                  </td>
                  <td>{selectedItemData.AwayShots.OnTargetAverageTotal}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Shots Against</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Away AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayShots.TotalVs}</td>
                  <td>{selectedItemData.AwayShots.AverageVsAtHomeOrAway}</td>
                  <td>{selectedItemData.AwayShots.AverageTotalVs}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total On Target</th>
                  <th>Away OT AVG</th>
                  <th>Total OT AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayShots.OnTargetTotalVs}</td>
                  <td>
                    {selectedItemData.AwayShots.OnTargetVsAverageAtHomeOrAway}
                  </td>
                  <td>{selectedItemData.AwayShots.OnTargetAverageTotalVs}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
