import React, { useMemo } from 'react';
import { MainMatchesData } from './MainMatchesData';
import { Header } from './Header';

interface Props {
  selectedItemData: MainMatchesData;
}

export const GoalPerMinuteAndHead2Head = ({ selectedItemData }: Props) => {
  if (!selectedItemData) {
    return <div>No data available</div>;
  }
  const getCellColorClassGoalsScoredPerMinute = (value: number): string => {
    let probabilityClass = '';
    if (value <= 1) {
      probabilityClass = 'dark-red cell-text';
    } else if (value <= 2) {
      probabilityClass = 'light-orange cell-text';
    } else if (value <= 4) {
      probabilityClass = 'light-yellow cell-text';
    } else if (value <= 6) {
      probabilityClass = 'light-green cell-text';
    } else if (value <= 8) {
      probabilityClass = 'medium-green cell-text';
    } else {
      probabilityClass = 'dark-green cell-text';
    }
    return probabilityClass;
  };

  const getCellColorClassGoalsConcededPerMinute = (value: number): string => {
    let probabilityClass = '';
    if (value <= 1) {
      probabilityClass = 'medium-green cell-text';
    } else if (value <= 2) {
      probabilityClass = 'light-yellow cell-text';
    } else if (value <= 4) {
      probabilityClass = 'dark-yellow cell-text';
    } else if (value <= 6) {
      probabilityClass = 'light-orange cell-text';
    } else {
      probabilityClass = 'dark-red cell-text';
    }
    return probabilityClass;
  };
  const getCellColorClass = (value: number): string => {
    let probabilityClass = '';
    if (value <= 10) {
      probabilityClass = 'dark-red cell-text';
    } else if (value <= 20) {
      probabilityClass = 'medium-red cell-text';
    } else if (value <= 30) {
      probabilityClass = 'light-red cell-text';
    } else if (value <= 40) {
      probabilityClass = 'dark-orange cell-text';
    } else if (value <= 50) {
      probabilityClass = 'light-orange cell-text';
    } else if (value <= 60) {
      probabilityClass = 'dark-yellow cell-text';
    } else if (value <= 70) {
      probabilityClass = 'light-yellow cell-text';
    } else if (value <= 80) {
      probabilityClass = 'light-green cell-text';
    } else if (value <= 90) {
      probabilityClass = 'medium-green cell-text';
    } else {
      probabilityClass = 'dark-green cell-text';
    }
    return probabilityClass;
  };

  const getMatchResultClass = (value: string): string => {
    if (value.includes('(W)')) {
      return 'light-green cell-text';
    }
    if (value.includes('(L)')) {
      return 'medium-red cell-text';
    }
    if (value.includes('(N)')) {
      return 'light-yellow cell-text';
    }

    return 'default';
  };

  return (
    <div className="TeamsAverageGoals">
      <div className="last5container">
        <div className="left-table">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th> Total </th>
                  <th colSpan={2}>Yellow Cards Per Match</th>
                </tr>
                <tr>
                  <th>Yellow Cards</th>
                  <th>Home Only</th>
                  <th>Home & Away</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeYellowCards.Total}</td>
                  <td>{selectedItemData.HomeYellowCards.AverageAtHome}</td>
                  <td>{selectedItemData.HomeYellowCards.AverageTotal}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Fouls</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Home AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeFouls.Total}</td>
                  <td>{selectedItemData.HomeFouls.AverageAtHomeOrAway}</td>
                  <td>{selectedItemData.HomeFouls.AverageTotal}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total Against</th>
                  <th>Home AVG Against</th>
                  <th>Total AVG Against</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeFouls.TotalVs}</td>
                  <td>{selectedItemData.HomeFouls.AverageVsAtHomeOrAway}</td>
                  <td>{selectedItemData.HomeFouls.AverageTotalVs}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Corners</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Home AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeCorners.Total}</td>
                  <td>{selectedItemData.HomeCorners.AverageAtHomeOrAway}</td>
                  <td>{selectedItemData.HomeCorners.AverageTotal}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total Against</th>
                  <th>Home AVG Against</th>
                  <th>Total AVG Against</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.HomeCorners.TotalVs}</td>
                  <td>{selectedItemData.HomeCorners.AverageVsAtHomeOrAway}</td>
                  <td>{selectedItemData.HomeCorners.AverageTotalVs}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="center-table">
          {
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th colSpan={2}>Team Home</th>
                    <th> </th>
                    <th colSpan={2}>Team Away</th>
                  </tr>
                  <tr>
                    <th>Home</th>
                    <th>Away</th>
                    <th>Opponents</th>
                    <th>Home</th>
                    <th>Away</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItemData.StandingOpponents.map((opponent, index) => (
                    <tr key={index}>
                      <td
                        className={getMatchResultClass(
                          opponent.HomeTeamHomeResult,
                        )}
                      >
                        {opponent.HomeTeamHomeResult}
                      </td>
                      <td
                        className={getMatchResultClass(
                          opponent.HomeTeamAwayResult,
                        )}
                      >
                        {opponent.HomeTeamAwayResult}
                      </td>
                      <td>{opponent.Opponent}</td>
                      <td
                        className={getMatchResultClass(
                          opponent.AwayTeamHomeResult,
                        )}
                      >
                        {opponent.AwayTeamHomeResult}
                      </td>
                      <td
                        className={getMatchResultClass(
                          opponent.AwayTeamAwayResult,
                        )}
                      >
                        {opponent.AwayTeamAwayResult}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>

        <div className="right-table">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th> Total </th>
                  <th colSpan={2}>Yellow Cards Per Match</th>
                </tr>
                <tr>
                  <th>Yellow Cards</th>
                  <th>Away Only</th>
                  <th>Home & Away</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayYellowCards.Total}</td>
                  <td>{selectedItemData.AwayYellowCards.AverageAtHome}</td>
                  <td>{selectedItemData.AwayYellowCards.AverageTotal}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Fouls</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Away AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayFouls.Total}</td>
                  <td>{selectedItemData.AwayFouls.AverageAtHomeOrAway}</td>
                  <td>{selectedItemData.AwayFouls.AverageTotal}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total Against</th>
                  <th>Away AVG Against</th>
                  <th>Total AVG Against</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayFouls.TotalVs}</td>
                  <td>{selectedItemData.AwayFouls.AverageVsAtHomeOrAway}</td>
                  <td>{selectedItemData.AwayFouls.AverageTotalVs}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>Corners</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Away AVG</th>
                  <th>Total AVG</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayCorners.Total}</td>
                  <td>{selectedItemData.AwayCorners.AverageAtHomeOrAway}</td>
                  <td>{selectedItemData.AwayCorners.AverageTotal}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Total Against</th>
                  <th>Away AVG Against</th>
                  <th>Total AVG Against</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItemData.AwayCorners.TotalVs}</td>
                  <td>{selectedItemData.AwayCorners.AverageVsAtHomeOrAway}</td>
                  <td>{selectedItemData.AwayCorners.AverageTotalVs}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
