export interface MainMatchesPrevisionData {
  MatchId: number;
  Country: string;
  MatchDate: string;
  League: string;
  Home: string;
  Away: string;
  PredictionType: string;
  ExactResult: string;
  Prob: number;
  MatchResult: string;
}

export interface MainMatchesData {
  MatchId: number;
  Country: string;
  MatchDate: string;
  League: string;
  Home: string;
  Away: string;
  HomePoints: number;
  AwayPoints: number;
  MatchPredictions: MatchPrediction[];
  AverageGoals: TeamsAverageGoals;
  Result1X2: Result1X2;
  Last5HomeMatches: MatchResult[];
  Last5MatchesHomeTeam: MatchResult[];
  Last5AwayMatches: MatchResult[];
  Last5MatchesAwayTeam: MatchResult[];
  HomeTeamHomeMatchesData: TeamMatchesData;
  AwayTeamAwayMatchesData: TeamMatchesData;
  HomeTeamProbabilities: TeamProbabilities;
  AwayTeamProbabilities: TeamProbabilities;
  MatchProbabilities: MatchProbabilities;
  HeadToHead: HeadToHeadData;
  HomeYellowCards: YellowCardsData;
  AwayYellowCards: YellowCardsData;
  HomeShots: ShotsData;
  AwayShots: ShotsData;
  HomeCorners: CornersData;
  AwayCorners: CornersData;
  HomeFouls: FoulsData;
  AwayFouls: FoulsData;
  HomeTopScorers: PlayerData[];
  AwayTopScorers: PlayerData[];
  HomeTopAssistmen: PlayerData[];
  AwayTopAssistmen: PlayerData[];
  StandingOpponents: StandingOpponent[];
  GoalsPerMinuteHomeTeam: GoalsPerMinuteData;
  GoalsPerMinuteAwayTeam: GoalsPerMinuteData;
  matchResult: string;
}

export interface MatchPrediction {
  predictionType: string;
  prob: number;
}

export interface TeamsAverageGoals {
  HT_HomeScoredAtHome: number;
  HT_HomeConcededAtHome: number;
  HT_HomeScoredLast5AtHome: number;
  HT_HomeConcededLast5AtHome: number;
  HT_AwayScoredAway: number;
  HT_AwayConcededAway: number;
  HT_AwayScoredLast5Away: number;
  HT_AwayConcededLast5Away: number;
  HomeTeamAllScored: number;
  HomeTeamAllConceded: number;
  AwayTeamAllScored: number;
  AwayTeamAllConceded: number;
  HomeTeamOnlyHomeScored: number;
  HomeTeamOnlyHomeConceded: number;
  AwayTeamOnlyAwayScored: number;
  AwayTeamOnlyAwayConceded: number;
  HomeTeamLast5HomeScored: number;
  HomeTeamLast5HomeConceded: number;
  AwayTeamLast5AwayScored: number;
  AwayTeamLast5AwayConceded: number;
}

export interface Result1X2 {
  Default_1: number;
  Default_X: number;
  Default_2: number;
  Poisson_1: number;
  Poisson_X: number;
  Poisson_2: number;
  Combined_1: number;
  Combined_X: number;
  Combined_2: number;
}

export interface MatchResult {
  HomeTeam: string;
  AwayTeam: string;
  Result: string;
  WLN: string;
}

export interface TeamMatchesData {
  Over15: number;
  Over25: number;
  Over35: number;
  CleanSheet: number;
  GoalGoal: number;
  NoGoal: number;
  TotalMatches: number;
}

export interface TeamProbabilities {
  Goals: GoalsProb;
  Over: OverProb;
  Under: UnderProb;
}

export interface GoalsProb {
  Goals_0: number;
  Goals_1: number;
  Goals_2: number;
  Goals_3: number;
  Goals_4: number;
  Goals_5: number;
}
export interface OverProb {
  Over_05: number;
  Over_15: number;
  Over_25: number;
  Over_35: number;
  Over_45: number;
  Over_55: number;
}
export interface UnderProb {
  Under_05: number;
  Under_15: number;
  Under_25: number;
  Under_35: number;
  Under_45: number;
  Under_55: number;
}

export interface MatchProbabilities {
  ExpectedGoalHome: number;
  ExpectedGoalAway: number;
  Over: OverProb;
  Under: UnderProb;
  GoalGoal: number;
  NoGoal: number;
  PossibleResults: PossibleResult[];
}

export interface PossibleResult {
  Home: number;
  Away: number;
  Probability: number;
  Odd: number;
}

export interface HeadToHeadData {
  Overall: HeadToHeadDetail;
  Team1AtHome: HeadToHeadDetail;
}

export interface HeadToHeadDetail {
  Matches: number;
  AverageHomeGoals: number;
  AverageAwayGoals: number;
  Result_1: number;
  Result_X: number;
  Result_2: number;
}

export interface YellowCardsData {
  Total: number;
  AverageAtHome: number;
  AverageTotal: number;
  TopPlayers: PlayerData[];
}

export interface ShotsData {
  Total: number;
  TotalAtHomeOrAway: number;
  AverageAtHomeOrAway: number;
  AverageTotal: number;

  OnTargetTotal: number;
  OnTargetTotalAtHomeOrAway: number;
  OnTargetAverageAtHomeOrAway: number;
  OnTargetAverageTotal: number;

  TotalVs: number;
  TotalVsAtHomeOrAway: number;
  AverageVsAtHomeOrAway: number;
  AverageTotalVs: number;

  OnTargetTotalVs: number;
  OnTargetTotalVsAtHomeOrAway: number;
  OnTargetVsAverageAtHomeOrAway: number;
  OnTargetAverageTotalVs: number;
}

export interface CornersData {
  Total: number;
  TotalAtHomeOrAway: number;
  AverageAtHomeOrAway: number;
  AverageTotal: number;

  TotalVs: number;
  TotalVsAtHomeOrAway: number;
  AverageVsAtHomeOrAway: number;
  AverageTotalVs: number;
}

export interface FoulsData {
  Total: number;
  TotalAtHomeOrAway: number;
  AverageAtHomeOrAway: number;
  AverageTotal: number;

  TotalVs: number;
  TotalVsAtHomeOrAway: number;
  AverageVsAtHomeOrAway: number;
  AverageTotalVs: number;
}

export interface PlayerData {
  Name: string;
  Count: number;
}

export interface StandingOpponent {
  HomeTeamHomeResult: string;
  HomeTeamAwayResult: string;
  Opponent: string;
  AwayTeamHomeResult: string;
  AwayTeamAwayResult: string;
}

export interface GoalsPerMinuteData {
  Scored: TotalGoalsMinute;
  Conceded: TotalGoalsMinute;
}

export interface TotalGoalsMinute {
  Minute_1_15: number;
  Minute_15_30: number;
  Minute_30_45: number;
  Minute_45_plus: number;
  Minute_45_60: number;
  Minute_60_75: number;
  Minute_75_90: number;
  Minute_90_plus: number;
}

const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const apiUrl = 'https://footballanalysis.giaka.dev/api';
// const apiUrl = 'https://localhost:7014/api';

export const getGoalGoalPredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=1&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getNoGoalPredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=2&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getUnder3Point5Predictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=4&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getOver2Point5Predictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=3&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getDrawPredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=6&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getExactResultPredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=5&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getOver1Point5Predictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=13&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getMultigol2_5Predictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=7&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getMultigol1_3Predictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=8&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getMultigol1_3HomePredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=9&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getMultigol1_3AwayPredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=10&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getUnder1Point5HomePredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=11&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getUnder1Point5AwayPredictions = async (
  date: string,
): Promise<MainMatchesPrevisionData[]> => {
  let matches: MainMatchesPrevisionData[] = [];
  const response = await fetch(
    `${apiUrl}/Previsions/predictions?typeId=12&date=${date}`,
  );
  matches = await response.json();
  return matches;
};

export const getMatchAnalysis = async (
  matchId: number,
): Promise<MainMatchesData> => {
  let match: MainMatchesData;
  const response = await fetch(`${apiUrl}/Previsions?matchId=${matchId}`);
  match = await response.json();
  return match;
};
